<template>
<div>
    <div class="wrap">
        <form name="mfrm" action="/menu_management_status/index" method="post">
            <div class="con_top">
                <h2><span>■</span> 협약센터승인</h2>
            </div>
            <div class="con">
                <div class="btns mb-30">
                    <a class="btn_new2 pointer" @click="register()">협약센터등록</a>
                </div>
                <div class="con_table">
                    <div class="search2">
                        <select v-model="permission" @change="getCenterList()" class="float-left font-16 w-130px h-40px mb-10">
                        <option value="">진행현황선택</option>
                        <option value="Y">승인완료</option>
                        <option value="N">승인신청</option>
                    </select>
                        <input type="hidden" name="_token" id="_token" value="EqLJL0tVa23F5ikcDmzYVhVNw50lB9k5eDl840Bi">
                        <input type="hidden" name="this_month" id="this_month" value="2020-10">
                        <div>등록일기간검색 :
                            <crm-datepicker v-model="startDate" :parentDate="startDate" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker> ~
                            <crm-datepicker v-model="endDate" :parentDate="endDate" class="mr-10" format="yyyy-MM-dd" :language="$store.state.ko"></crm-datepicker>
                            <a class="btn_search pointer" @click="getCenterList()">Search<span></span></a>
                        </div>
                    </div>
                    <table class="default_table mt-20">
                        <tbody v-if="loading">
                            <tr>
                                <th>No</th>
                                <th>센터등록날짜</th>
                                <th>센터명</th>
                                <th>센터장</th>
                                <th>전화번호<br>(센터장)</th>
                                <th>진행현황</th>
                                <th>관리</th>
                            </tr>
                            <tr>
                                <td colspan="7">검색중입니다.</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <th>No</th>
                                <th>센터등록날짜</th>
                                <th>센터명</th>
                                <th>센터장</th>
                                <th>전화번호<br>(센터장)</th>
                                <th>진행현황</th>
                                <th>관리</th>
                            </tr>
                            <tr v-for="(item, index) of centerList" :key="index">
                                <td>{{item.rownum}}</td>
                                <td>{{item.createDate.slice(0,10)}}</td>
                                <td>{{item.name}}</td>
                                <td>{{item.centerCap}}</td>
                                <td>{{item.phone}}</td>
                                <td>{{formatYN(item.permission)}}</td>
                                <td><!-- <span class="underline pointer" @click="preview(centerList[index])">미리보기</span><span v-if="item.permission !== 'Y'"> -->
                                    <span class="underline pointer" @click="detail(item.idx)">상세보기</span><span v-if="item.permission !== 'Y'"> | </span><span v-if="item.permission !== 'Y'" @click="putPermission(item.idx)" class="underline pointer">승인</span></td>
                            </tr>
                            <tr v-if="centerList.length === 0">
                                <td colspan="7">검색 결과가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="con_table">
                    <div class="page">
                        <ul>
                            <li>
                                <a class="pointer" @click="prevPaging()"> &lt; </a>
                            </li>
                            <li v-for="index in calcMaxPaging()" :key="index" class="txt-blue">
                                <span class="pointer" style="padding: 8px;" @click="paging(index)">
                                    <a :class="{'bold': index === pageNum}">{{index}}</a>
                                </span>
                            </li>
                            <li>
                                <a class="pointer" @click="nextPaging()"> &gt; </a>
                            </li>

                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <preview v-on:permission="putPermission" :onModal.sync="onModal" :center.sync="center"></preview>
</div>
</template>

<script>
import Preview from '@/components/admin/confirmConvention/preview.vue'
export default {
    components: {
        Preview
    },
    data: () => ({
        startDate: '', // 기간검색-시작날짜
        endDate: '', // 기간검색-종료날짜
        onModal: false, // 미리보기 모달 on/off

        idx: -1,
        type: 'CENTER',
        centerList: [],
        center: {},

        permission: '',

        // 페이징에 필요한 변수들
        pageNum: 1,
        pageSize: 50,
        listLength: 0, // 리스트 size
        maxPagingCount: 1, // 최대 페이지 수
        maxPagePart: 1, // 최대 파트가 몇번째인지
        pagePart: 1, // 현재 페이징이 몇번째 파트에 있는지 (ex 1~10 1파트, 11~20 2파트)
        loading:true,
    }),

    mounted() {

        if(sessionStorage.getItem('admin_convention_pageNum') !== 'undefined' && sessionStorage.getItem('admin_convention_pageNum') !== null) {
            this.pageNum = Number(sessionStorage.getItem('admin_convention_pageNum'))
        }

        if(sessionStorage.getItem('admin_convention_startDate') !== 'undefined' && sessionStorage.getItem('admin_convention_startDate') !== null) {
            this.startDate = sessionStorage.getItem('admin_convention_startDate')
        }

        if(sessionStorage.getItem('admin_convention_endDate') !== 'undefined' && sessionStorage.getItem('admin_convention_endDate') !== null) {
            this.endDate = sessionStorage.getItem('admin_convention_endDate')
        }

        if(sessionStorage.getItem('admin_convention_permission') !== 'undefined' && sessionStorage.getItem('admin_convention_permission') !== null) {
            this.permission = sessionStorage.getItem('admin_convention_permission')
        }
        
        this.getCenterList()
        //this.startDate = sessionStorage.getItem('admin_convention_startDate')
        //this.endDate = sessionStorage.getItem('admin_convention_endDate')
    },

    methods: {

        // 이전 페이징
        prevPaging() {
            if (this.pageNum === 1) {
                return false
            } else {
                this.pageNum -= 1
                this.getCenterList()
            }
        },
        paging(index) {
            this.pageNum = index
            this.getCenterList()
        },

        // 현재 페이지의 페이지 범위 계산
        calcMaxPaging() {
            if (this.maxPagingCount > this.pagePart * 10) {
                this.maxPagePart = this.pagePart * 10
            } else {
                this.maxPagePart = this.maxPagingCount
            }
            var pagingArray = []
            for (var i = ((this.pagePart - 1) * 10) + 1; i <= this.maxPagePart; i++) {
                pagingArray.push(i)
            }
            return pagingArray
        },

        // 다음 페이징
        nextPaging() {
            if (this.pageNum === this.maxPagingCount) {
                return false
            } else {
                this.pageNum += 1
                this.getCenterList()
            }
        },

        // 오늘 날짜 YYYY-MM-DD로 지정하는 함수
        formatDate(date) {
            if (date) {
                var d = new Date(date),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            } else if (date === '') {
                return ''
            } else {
                var d = new Date(),
                    month = '' + (d.getMonth() + 1),
                    day = '' + d.getDate(),
                    year = d.getFullYear();
                if (month.length < 2) month = '0' + month;
                if (day.length < 2) day = '0' + day;
                return [year, month, day].join('-');
            }
        },

        getCenterList() {

            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startDate: this.startDate,
                endDate: this.endDate,
                permission: this.permission
            }
            
            sessionStorage.setItem('admin_convention_pageNum', this.pageNum)
            sessionStorage.setItem('admin_convention_startDate', this.formatDate(this.startDate))
            sessionStorage.setItem('admin_convention_endDate', this.formatDate(this.endDate))
            sessionStorage.setItem('admin_convention_permission', this.permission)

            this.loading = true;

            this.axios.get('/api/v1/center/list', {
                    params: params
                })
                .then(res => {
                    if (res.data.err === 0) {
                        this.centerList = res.data.centerList

                        if (res.data.centerListCount) {
                            this.listLength = res.data.centerListCount
                        }

                        this.maxPagingCount = Math.ceil(this.listLength / this.pageSize)
                        this.maxPagePart = Math.ceil(this.maxPagingCount / 10)
                        this.pagePart = Math.ceil(this.pageNum / 10)

                    } else {
                        this.centerList = []
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(()=>{
                    this.loading = false;
                })
        },

        // 상담일지 미리보기 (모달 on)
        preview(centerList) {
            this.onModal = !this.onModal
            this.center = centerList
        },

        // 상담일지 닫기 (모달 off)
        offModal() {
            this.onModal = false
        },

        formatYN(flag) {
            if (flag === 'Y') {
                return '승인완료'
            } else {
                return '승인신청'
            }
        },

        // 센터등록 페이지로 이동
        register() {
            this.$router.push('/origin/center_manage_register')
        },

        // 승인
        putPermission(idx) {

            this.idx = idx

            var params = new URLSearchParams();
            params.append('type', this.type)
            params.append('idx', this.idx)

            this.axios.put('/api/v1/admin/permission', params)
                .then(res => {
                    alert('승인 되었습니다.')
                    this.$router.go(this.$router.currentRoute)
                })
                .catch(err => {
                    alert(err)
                    console.log(err)
                    if (err.response.data.path == '/login/msg') {
                        alert('세션이 끊어졌습니다. 로그인 페이지로 이동합니다.')
                        this.$router.push('/login')
                    } else {
                        alert('관리자에게 문의하세요')
                    }
                })

        },

        // 센터정보 상세보기 페이지로 이동
        detail(idx) {
            this.$router.push(`/origin/center_manage_info?idx=${idx}`)
        },

    }
}
</script>
